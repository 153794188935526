@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap);
#Introduction {
  min-height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
}

#MoreInformation {
  padding: 40px 40px 0px 40px;
  min-height: 70vh;
  display: flex;
  justify-content: center;
}

.card {
  outline: black 1px;
  background-color: #2c2c2c;
  border-radius: 6px;
  padding: 20px;
  box-shadow: #323232 0px 0px 12px;
  min-height: 60vh;
  padding: 30px;
}

.card h4 {
  color: #c4c4c4;
}

.experienceTitle {
  color: #c4c4c4;
  font-weight: 700 !important;
}

.cardItem {
  padding-top: 30px;
}


li::marker {
  color: gray
}

.myPhoto {
  max-height: 400px;
  max-width: 400px;
  box-shadow: #454545 0px 0px 12px;
  border-radius: 50%;
}


.scrollArrow {
  position: absolute;
  bottom: 0px;
  margin-top: 100px;
  margin-bottom: 0px;
  -webkit-animation-name: bob;
          animation-name: bob;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-tap-highlight-color: transparent;
}

.scrollArrow:hover {
  cursor: pointer;
}

.visitExp {
  color: #b8b8b8;
}

.visitExp:hover {
  color: gray;
}


@-webkit-keyframes bob {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}


@keyframes bob {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}
.closeButton {
  float: right;
  margin: -5px -5px 0px 0px !important;
}

.demoDialog {
  color: #1d1d1d !important;
}

.projectTitle {
  color: gray !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  float: left;
  margin: 0 auto;
}
#Projects h6 {
  color: #c4c4c4;
}

#Projects h2 {
  color: #c4c4c4;
  text-shadow: #252525 0px 0px 18px;
}

.github {
  color: #b8b8b8;
}

.projectCard {
  outline: black 1px;
  background-color: #161616;
  border-radius: 6px;
  padding: 20px;
  box-shadow: #252525 0px 0px 8px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #2f2f2f;
  color: white;
  text-align: center;
  border-radius: 5px;

  position: absolute;
  top: 100%;
  margin-top: 5px;
  margin-left: -50%;
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  padding: 5px 20px 5px 20px;
  box-shadow: #161616 0px 0px 8px;
}

.viewDemo {
  color: #b8b8b8;
  float: right;
  margin: 0 auto;
  margin-top: 5px;
}

.visit {
  color: #b8b8b8;
  /* padding-right: 30px; */
  float: right;
  margin: 0 auto;
  margin-top: 5px;
}

.visit:hover, .viewDemo:hover, .github:hover {
  color: gray;
  cursor: pointer;
}
.navigationBar {
  background-color: #1d1d1d;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps;
}

.navigationBar h6 {
  color: white;
}

.navigationBar h6:hover {
  color: #6BD0FF;
}
body {
  margin: 0 auto;
  background-color: #404040;
}

a {
  color: gray;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

h2, h4 {
  color: white;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
}

h5, h6, p {
  color: gray;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
}

.grow:hover {
  transition: all .1s ease-in-out;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  z-index: inherit;
}
