#Introduction {
  min-height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
}

#MoreInformation {
  padding: 40px 40px 0px 40px;
  min-height: 70vh;
  display: flex;
  justify-content: center;
}

.card {
  outline: black 1px;
  background-color: #2c2c2c;
  border-radius: 6px;
  padding: 20px;
  box-shadow: #323232 0px 0px 12px;
  min-height: 60vh;
  padding: 30px;
}

.card h4 {
  color: #c4c4c4;
}

.experienceTitle {
  color: #c4c4c4;
  font-weight: 700 !important;
}

.cardItem {
  padding-top: 30px;
}


li::marker {
  color: gray
}

.myPhoto {
  max-height: 400px;
  max-width: 400px;
  box-shadow: #454545 0px 0px 12px;
  border-radius: 50%;
}


.scrollArrow {
  position: absolute;
  bottom: 0px;
  margin-top: 100px;
  margin-bottom: 0px;
  animation-name: bob;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  -webkit-tap-highlight-color: transparent;
}

.scrollArrow:hover {
  cursor: pointer;
}

.visitExp {
  color: #b8b8b8;
}

.visitExp:hover {
  color: gray;
}


@keyframes bob {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}