@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

body {
  margin: 0 auto;
  background-color: #404040;
}

a {
  color: gray;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

h2, h4 {
  color: white;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
}

h5, h6, p {
  color: gray;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
}

.grow:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.05);
  z-index: inherit;
}