#Projects h6 {
  color: #c4c4c4;
}

#Projects h2 {
  color: #c4c4c4;
  text-shadow: #252525 0px 0px 18px;
}

.github {
  color: #b8b8b8;
}

.projectCard {
  outline: black 1px;
  background-color: #161616;
  border-radius: 6px;
  padding: 20px;
  box-shadow: #252525 0px 0px 8px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #2f2f2f;
  color: white;
  text-align: center;
  border-radius: 5px;

  position: absolute;
  top: 100%;
  margin-top: 5px;
  margin-left: -50%;
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  padding: 5px 20px 5px 20px;
  box-shadow: #161616 0px 0px 8px;
}

.viewDemo {
  color: #b8b8b8;
  float: right;
  margin: 0 auto;
  margin-top: 5px;
}

.visit {
  color: #b8b8b8;
  /* padding-right: 30px; */
  float: right;
  margin: 0 auto;
  margin-top: 5px;
}

.visit:hover, .viewDemo:hover, .github:hover {
  color: gray;
  cursor: pointer;
}